import NP from 'number-precision';
import { CODE_CLASS_LIST, STORAGE } from './const';
const moment = require('moment');

export const getCodeList = (cicName = "", config = { showAll: false }) => {
    if (!cicName) return [];
    // 复制，防止污染原数据
    let codeList = (CODE_CLASS_LIST[cicName] || []).slice();
    // 添加【全部】选项
    if (config.showAll) {
        codeList.unshift({ code: null, name: '全部' });
    }
    return codeList;
}

export const setLocalStorage = (key, data) => {
    localStorage.setItem(key, data);
}

// 设置本地存储的state值
export const setStateDataStorage = (key, data) => {
    let rjStateData = JSON.parse(localStorage.getItem(STORAGE.RJ_STATE_DATA) || '{}');
    rjStateData[key] = { ...data };
    setLocalStorage(STORAGE.RJ_STATE_DATA, JSON.stringify(rjStateData));
}

export const dateDiff = (dateTimeStamp) => {
    dateTimeStamp = Number(dateTimeStamp);
    let minute = 1000 * 60;
    let hour = minute * 60;
    let result;

    let curDate = new Date();
    let curStart = new Date(curDate.toLocaleDateString()).getTime();
    let diffValue = curDate.getTime() - dateTimeStamp;

    if (diffValue < 2 * hour) {
        if (diffValue < hour) {
            result = `${Math.floor(diffValue / minute)}分钟之前`;
        } else {
            result = `${Math.floor(diffValue / hour)}小时之前`;
        }
    } else if (curStart < dateTimeStamp) {
        result = `今天 ${moment(dateTimeStamp).format('HH:mm:ss')}`;
    } else {
        result = moment(dateTimeStamp).format('YYYY-MM-DD HH:mm:ss');
    }
    return result;
}

export const highlightSingleRender = (keyword = '', value) => {
    let replaceReg = new RegExp(keyword, 'g');
    let replaceString = `<span class="danger">${keyword}</span>`;
    return value.replace(replaceReg, replaceString);
};

export const highlightRender = (keyword = [], value) => {
    if (value == null) return '';
    if (typeof value === 'number') {
        value = String(value);
    }
    if (keyword instanceof Array) {
        let str = value;
        keyword.map(word => {
            str = highlightSingleRender(word, str);
        });
        return str;
    } else {
        highlightSingleRender(keyword, value);
    }
};

// 获取本地token
export const getLocalStorageToken = () => {
    return localStorage.getItem('shiyaoToken') || '';
}

// 从数组中选取随机数
export const getRandomFromArr = (arr) => {
    let copyArr = JSON.parse(JSON.stringify(arr))
    let index = Math.floor(Math.random() * copyArr.length);
    let code = copyArr[index]
    copyArr.splice(index, 1)
    return {
        code,
        newArr: copyArr
    }
}

// 将数组中某一项移动到最前面
export const moveElement = (arr, key) => {
    let copyArr = JSON.parse(JSON.stringify(arr))
    let tempArr = copyArr.splice(key, 1)
    copyArr = [...tempArr, ...copyArr]
    return copyArr
}

// 获取范围内的随机数
export const getRandomRange = (max, min) => {
    const rangeNum = NP.minus(max, min)
    const randomValue = NP.times(Math.random(), rangeNum)
    const rangeValue = NP.plus(randomValue, min)
    const fixed6Value = NP.round(rangeValue, 6)
    return fixed6Value
}

// 判断地图坐标点是不是在对应省份内
export const IsPtInPoly = (aLat, aLon, pointList) => {
    /* 
    :param aLon: double 经度 
    :param aLat: double 纬度 
    :param pointList: 边框数组 多边形点的顺序需根据顺时针或逆时针，不能乱 
    */
    var iSum = 0  
    var iCount = pointList.length
      
    if(iCount < 3) {
        return false 
    }
    //  待判断的点(x, y) 为已知值
    var y = aLat
    var x = aLon
    let y2,x2
    for(var i = 0; i < iCount; i++) {
        var y1 = pointList[i][0]  
        var x1 = pointList[i][1]
        if(i == iCount - 1) {
            y2 = pointList[0][0]
            x2 = pointList[0][1]
        } else {
            y2 = pointList[i + 1][0]  
            x2 = pointList[i + 1][1]
        }
        // 当前边的 2 个端点分别为 已知值(x1, y1), (x2, y2)
        if (((y >= y1) && (y < y2)) || ((y >= y2) && (y < y1))) {
            if (Math.abs(y1 - y2) > 0) {
                var x_intersect = x1 - ((x1 - x2) * (y1 - y)) / (y1 - y2);  
                if(x_intersect < x) {
                    iSum += 1 
                }
            }
        } 
    }
    if(iSum % 2 != 0) {
        return true  
    }else {
        return false 
    }  
  }

// 判断上次选择省份是否存在，不存在时默认去可切换省份第一个
export const initLsUserGeo = () => {
    const lastUserGeo = JSON.parse(localStorage.getItem(STORAGE.RJ_SPECIAL_USER_GEO))
    if(lastUserGeo) {
        if(!lastUserGeo?.data.filter(item => { return item.code === lastUserGeo.code }).length) {
            lastUserGeo.code = lastUserGeo.data[0].code
            lastUserGeo.text = lastUserGeo.data[0].text
        }
        localStorage.setItem(STORAGE.RJ_SPECIAL_USER_GEO, JSON.stringify({
            ...lastUserGeo,
            code: lastUserGeo.code,
            text: lastUserGeo.text
        }))
    }
}
// 获取url中的参数
export const getUrlParam = (name, url=window.location.href) => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); // 构造一个含有目标参数的正则表达式对象
    let r = url.split('?')[1];
    if (r) {
        r = url.split('?')[1].match(reg);  // 匹配目标参数
        if (r !== null) return unescape(r[2]); return null; // 返回参数值
    }
    return null;
}