import { USER } from '@/remote';
import { setLocalStorage } from '@/utils';
import { STORAGE } from '@/utils/const';
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let resourceDataStorage = JSON.parse(localStorage.getItem(STORAGE.RJ_RESOURCE_INFO) || '{}');
const State = {
  resourceData: resourceDataStorage,
  currentProvince: {}
}
const SAVE_RESOURCE_DATA = 'SAVE_RESOURCE_DATA'
const ACTIONS = {
  queryResource: 'queryResource'
}

const store = new Vuex.Store({
  state: State,
  getters: {
    currentProvince: state => state.currentProvince,
    resourceData: state => state.resourceData,
  },
  mutations: {
    setCurrentProvince: (state, data) => {
      state.currentProvince = data
    },
    [SAVE_RESOURCE_DATA]: (state, data = {}) => {
      state.resourceData = {
        ...data,
      }
      setLocalStorage(STORAGE.RJ_RESOURCE_INFO, JSON.stringify(state.resourceData));
    },
  },
  actions: {
    [ACTIONS.queryResource] ({state, commit}, {bizType, cb}) {
      USER.queryResource(bizType).then(res => {
        let {data = []} = res;
        let dataObj = data.reduce((obj, item) => {
          obj[item.resourceName] = item;
          return obj;
        }, {})
        cb && cb(dataObj);
        commit(SAVE_RESOURCE_DATA, dataObj);
      })
    },
  },
  modules: {},
});

export { ACTIONS };
export const state = store.state
export const commit = store.commit
export const dispatch = store.dispatch
export default store;
