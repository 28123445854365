
import { STORAGE } from '@/utils/const';
import { message } from '@/utils/message';
import axios from 'axios';
import { Loading } from 'element-ui';
import router from "../router";
import { TIPS } from './tips';

/* 页面加载 */
const pageLoading = {
  elem: null,
  show() {
    this.elem = Loading.service({
      fullscreen: true,
      background: 'rgba(0,0,0,0.7)'
    });
  },
  close() {
    this.elem && this.elem.close()
  }
}

//请求拦截
axios.interceptors.request.use(

  config => {
    let { loading = true, tips = TIPS.default, showMessage = true, isOther = false } = config || {};
    config.baseURL = process.env.VUE_APP_BASE_URL
    // config.withCredentials = false;
    config.timeout = 5 * 60 * 1000;
    config.showMessage = showMessage;

    //设置请求头token，第三方不加token
    const token = localStorage.getItem(STORAGE.RJ_SPECIAL_TOKEN);
    if (token && !isOther) {
      config.headers.token = token;
    }
    
    //显示加载
    if(config.url !== 'api/biz/clue/list') {
      loading && pageLoading.show(tips);
    }
    return config;
  },
  error => {
    return Promise.reject(error)
  }
)
//响应拦截
axios.interceptors.response.use(
  response => {
    let showMessage = response.config.showMessage;
    const res = response.data;
    const code = res.code * 1;
    pageLoading.close();

    if (!Object.keys(res).includes('code')) {
      return res;
    }

    if (code != 200) {
      // 特殊提示
      if (code == 4001) {
        showMessage && message.warning(res.msg);
        return;
      }
      showMessage && message.error(res.msg || '请求失败');
      // goLogin标记是否跳转到登录页
      // 登录页不重复redirect到登录页，现在后端登录接口有问题，所以加上这段逻辑
      if (code == 401 && res.data && res.data.goLogin == 1 && (router.history && router.history.current.name != 'Login')) {
        localStorage.removeItem(STORAGE.RJ_SPECIAL_TOKEN); //清除本地token
        router.replace({
          name: 'Login',
          query: {redirect: window.location.pathname}
        });
      }
      return Promise.reject(res);
    }
    return res;
  },
  error => {
    let status = error.response.status;
    if (status != 200) {
      message.error(error.response.msg || '请求失败');
    }
    pageLoading.close();
    return Promise.reject(error)
  }
)

export default axios;
