// 本地缓存
export const STORAGE = {
    RJ_SPECIAL_TOKEN: 'rjSuspiciousPublicOpinionToken',
    RJ_SPECIAL_USER_INFO: 'rjSuspiciousPublicOpinionUserInfo',
    RJ_SPECIAL_USER_GEO: 'rjSuspiciousPublicOpinionUserGeo',
    RJ_STATE_DATA: 'rjSuspiciousPublicOpinionStateData',
    RJ_CLUE_SEARCH_DATA: 'rjClueSearchData',
    RJ_SPECIAL_USER_SUBJECTS: 'rjSpecialUserSubjects',
    RJ_RESOURCE_INFO: 'rjSpecialResourceInfo'
}

// 关键节点
export const TIME_LINE_MAIN_LIST = [
    {text: '最新线索', key: 'LATEST'},
    {text: '关键节点', key: 'KEY_POINT'},
    {text: '传播源头', key: 'SOURCE'},
]

export const ROUTER_URL = {
    login: { url: '/login', key: 'Login' },
    home: { url: '/', key: 'Home' },
    report: { url: '/report', key: 'Report' },
    message: { url: '/message', key: 'Message' },
    subject: { url: '/subject', key: 'Subject' }
};

// 平台图片
export const PLATFORM_ICON = {
    '微信': require('@/assets/img/icon-wechat.png'),
    '微博': require('@/assets/img/icon-weibo.png'),
    '头条': require('@/assets/img/icon-toutiao.png'),
    '抖音': require('@/assets/img/icon-douyin.png'),
    '快手': require('@/assets/img/icon-kuaishou.png'),
    '小红书': require('@/assets/img/icon-hong.png'),
    'twitter': require('@/assets/img/icon-twitter.png'),
}

export const CODE_CLASS = {
};

export const CODE_CLASS_LIST = (() => {
    let codeClassList = {};
    // 格式转换
    for (let key in CODE_CLASS) {
        codeClassList[key] = [];
        for (let k in CODE_CLASS[key]) {
            codeClassList[key].push({
                code: k,
                name: CODE_CLASS[key][k]
            });
        }
    }
    return codeClassList;
})();

export const strIsNull = ((str) => {
    if (!str || 'null' == str) {
        return true;
    } else {
        return false;
    }
})
/**
 * 时间对象的格式化;
 */
Date.prototype.format = function (format) {
    /*
     * eg:format="YYYY-MM-dd hh:mm:ss";
     */
    var dateList = {
        "M+": this.getMonth() + 1, // month
        "d+": this.getDate(), // day
        "h+": this.getHours(), // hour
        "m+": this.getMinutes(), // minute
        "s+": this.getSeconds(), // second
        "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
        "S": this.getMilliseconds()
        // millisecond
    }

    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (var k in dateList) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? dateList[k] : ("00" + dateList[k]).substr(("" + dateList[k]).length));
        }
    }
    return format;
};
String.prototype.sub2 = function (n, s) {
    let r = /[^\x00-\xff]/g;
    if ('' == s) {
        return;
    } else {
        s = s || '...';
    }
    if (this.replace(r, "mm").length <= n)
        return this;
    // n = n - 3;
    let m = Math.floor(n / 2);
    for (let i = m; i < this.length; i++) {
        if (this.substr(0, i).replace(r, "mm").length >= n) {
            return this.substr(0, i) + s;
        }
    }
    return this;
};

