import axios from '@/remote/axios.config';
import echarts from "echarts";
import ElementUI from 'element-ui';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as consts from "./utils/const";
import { message } from './utils/message';

import 'element-ui/lib/theme-chalk/index.css';
import "./assets/less/base.less";

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.echarts = echarts;
Vue.prototype.consts = consts;
Vue.prototype.$axios = axios;
Vue.prototype.$message = message

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title || '睿媒AI识谣平台';
  }
  next();
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
