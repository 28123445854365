import { ROUTER_URL, STORAGE } from "@/utils/const";
import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter)

const routes = [
  {
    path: ROUTER_URL.login.url,
    name: ROUTER_URL.login.key,
    component: () => import('../views/Login/Index.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: ROUTER_URL.home.url,
    name: ROUTER_URL.home.key,
    component: () => import('../views/Home/Index.vue'),
    meta: {
      title: '睿媒AI识谣平台',
      requireAuth: true,//登录权限
    }
  },
  {
    path: ROUTER_URL.report.url,
    name: ROUTER_URL.report.key,
    component: () => import('../views/Home/Report.vue'),
    meta: {
      title: '睿媒AI识谣平台',
      requireAuth: true,//登录权限
    }
  },
  {
    path: ROUTER_URL.message.url,
    name: ROUTER_URL.message.key,
    component: () => import('../views/Message/Message.vue'),
    meta: {
      title: '睿媒AI识谣平台',
      requireAuth: true,//登录权限
    }
  },
  {
    path: ROUTER_URL.subject.url,
    name: ROUTER_URL.subject.key,
    component: () => import('../views/Subject/Subject.vue'),
    meta: {
      title: '睿媒AI识谣平台',
      requireAuth: true,//登录权限
    }
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((route, redirect, next) => {
  if (!localStorage.getItem(STORAGE.RJ_SPECIAL_TOKEN) && route.name !== 'Login') {
    next({
      name: 'Login',
      query: {redirect: route.fullPath}
    });
  } else {
    next();
  }
})

export default router
