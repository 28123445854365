import axios from './axios.config';

export const USER = {
  login(data) {
    return axios({
      method: 'POST',
      url: `api/sys/login`,
      data
    });
  },
  logout() {
    return axios({
      method: 'POST',
      url: `api/sys/logout`,
    });
  },
  // 获取logo和title等
  queryResource(bizType = 'default') {
    return axios({
      method: 'get',
      url: `api/sys/resource/queryResource.json?bizType=${bizType}`,
      loading: false,
    });
  }
};

export const MAIN = {
  // 全局数据
  getGeoGlobalData(data, headers) {
    return axios({
      method: 'get',
      url: `api/stat/main/stat_geo_global`,
      loading: false,
      params: data,
      headers
    });
  },

  // 平台分布
  getGeoSiteData(data) {
    return axios({
      method: 'get',
      url: `api/stat/main/stat_geo_site`,
      loading: false,
      params: data
    });
  },

  // 关键疑点
  getGeoKeyPointData(data) {
    return axios({
      method: 'get',
      url: `api/stat/main/stat_geo_keypoint`,
      loading: false,
      params: data
    });
  },

  // 可疑舆情趋势
  getGeoTimeTrendData(data) {
    return axios({
      method: 'get',
      url: `api/stat/main/stat_geo_timetrend`,
      loading: false,
      params: data
    });
  },

  // 可疑词云
  getGeoTimeWordCloudData(data) {
    return axios({
      method: 'get',
      url: `api/stat/main/stat_geo_wordcloud`,
      loading: false,
      params: data
    });
  },

  // 专题数据
  getSubjectData(params) {
    return axios({
      method: 'get',
      url: `api/sys/subject/list`,
      loading: false,
      params
    });
  },
};

export const SYSTEM = {
  getEnumData() {
    return axios({
      method: 'get',
      url: `api/biz/main/const`,
      loading: false,
    });
  },
}


export const CLUE = {
  // 地图展示
  getClueListGeo(data, headers) {
    return axios({
      method: 'get',
      url: `api/biz/clue/list-geo`,
      params: data,
      headers,
      loading: false
    });
  },

  // 舆情列表
  getClueList(data, headers, loading) {
    return axios({
      method: 'get',
      url: `api/biz/clue/list`,
      params: data,
      headers,
      loading
    });
  },

  // 舆情详情
  getClueInfo(id) {
    return axios({
      method: 'get',
      url: `api/biz/clue/info/${id}`,
    });
  },

  // 舆情详情-相关舆情
  getClueInfoSimilar(id) {
    return axios({
      method: 'get',
      url: `api/biz/clue/info/${id}/similar`,
    });
  },

  // 舆情详情-一键追溯
  getClueTimeline(id) {
    return axios({
      method: 'get',
      url: `api/biz/clue/info/${id}/timeline`,
      loading: false
    });
  },

  // 关键疑点—单个（暂时不用了）
  getClueKeypoint(id) {
    return axios({
      method: 'get',
      url: `api/biz/clue/info/${id}/keypoint`,
      loading: false
    });
  },

  // 关键疑点—批量
  async getClueKeypointBatch(data) {
    const res = await axios({
      method: 'post',
      url: `api/biz/clue/info/batch/keypoint`,
      loading: false,
      data
    });
    const values = [...Object.values(res.data)]
    values.map(value => {
      if(value) {
        value.map(child => {
          if(child.detectCode == 107 && child.detectResult && child.detectResult.length) {
            child.detectResult[0].message = '该信息来源不明确，或未被证实，非当事人或官方机构发布，可信度较低'
            delete child.detectResult[0]._pattern
          } else if(child.detectCode == 107 && child.detectResult && child.detectResult.length === 0) {
            child.detectResult.push({
              message: '该信息来源不明确，或未被证实，非当事人或官方机构发布，可信度较低'
            })
          }
          return child
        })
      }
      return value
    })
    const keys = [...Object.keys(res.data)]
    keys.map((item, index) => {
      res.data[item] = values[index]
    })
    return res
  },

  // 反馈-最新信息
  getClueFeedbackLoad(params) {
    return axios({
      method: 'get',
      url: `api/biz/clue/feedback/load`,
      loading: false,
      params
    });
  },

  // 反馈-列表查询
  getClueFeedbackList(params) {
    return axios({
      method: 'get',
      url: `api/biz/clue/feedback/list`,
      loading: false,
      params
    });
  },

  // 反馈-提交结果
  postClueFeedback(data) {
    return axios({
      method: 'post',
      url: `api/biz/clue/feedback/post`,
      loading: false,
      data
    });
  },
  
  // aigc检测
  postClueAigc(id) {
    return axios({
      method: 'get',
      url: `api/biz/clue/aigc/${id}`,
      loading: false,
    })
  }
}